// src/MapComponent.js

import React, { useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import img from "../TodayCommercial/visites/marker.png";
import img1 from "../TodayCommercial/visites/marker-icon-2x.png";
const customIconClient = new L.Icon({
    iconUrl: img,
    iconSize: [35, 45],
    iconAnchor: [17, 45],
    popupAnchor: [0, -45],
});
const customIconVente = new L.Icon({
    iconUrl: img1,
    iconSize: [35, 45],
    iconAnchor: [17, 45],
    popupAnchor: [0, -45],
});

const MapComponent = ({ Clients, ventes }) => {
    const [center, setCenter] = useState({
        lat: Clients? Number(Clients[0].latitude)||0 : 36.768361327434,
        lng:  Clients? Number(Clients[0].longitude)||0 : 10.193696279171,
    });
    const ZOOM_LEVEL = 12;
    const mapRef = useRef();

    const handleItineraireClick = (latitude, longitude) => {

        const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
        window.open(url, '_blank');
    };

    return (
        <div className="left-container1 mt-5">
            
            <MapContainer center={center} zoom={ZOOM_LEVEL} ref={mapRef}>
                <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/olfamah/ckr5572fg13mr17nzhlm83yew/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib2xmYW1haCIsImEiOiJja3IxMTZ3aXoxZ254Mm9ueGhtdXdxcXNhIn0.Ua3HPE8z1QpRMeXbbtUeig"
                />


                {Clients && Clients.map((i, key) => (
                    <Marker
                        key={key}
                        position={[Number(i.latitude)||0, Number(i.longitude)||0]}
                        icon={customIconClient} // Use the custom icon here
                    >
                        <Popup>
                            <p ><strong style={{ fontWeight: 'bold' }}>Code</strong>: {i.code}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Client </strong>: {i.name}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Magasin</strong>: {i.magasin}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Gouvernorat</strong>: {i.gouvernorat}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Delegation</strong>: {i.delegation}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Derniere visite</strong>: {i.last_visite}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Dernier Bl</strong>: {i.last_bl}</p>
                            <button onClick={() => handleItineraireClick(i.latitude, i.longitude)}>itinéraire</button>
                        </Popup>
                    </Marker>
                ))}
                {ventes && ventes.map((i, key) => (
                    <Marker
                        key={key}
                        position={[Number(i.latitude)||0, Number(i.longitude)||0]}
                        icon={customIconVente}
                    >
                        <Popup>
                            <p><strong style={{ fontWeight: 'bold' }}>Code</strong>: {i.client_code}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Client </strong>: {i.name}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Date</strong>: {i.date}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Entet</strong>: {i.entetecommercial_code}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Quantite</strong>: {i.bl_quantite}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Poids</strong>: {i.bl_poids}</p>
                            <p><strong style={{ fontWeight: 'bold' }}>Chiffre</strong>: {i.bl_chiffre}</p>
                        </Popup>
                    </Marker>)
                )}
            </MapContainer>
        </div>
    );
};

export default MapComponent;
