/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import "../TodayCommercial/visites/map.css";
import {
  getHistoriqueByMonth,
} from "../../services/clients/ClientApi";


export default function RapportVisite({ code ,t}) {
  
  const [visites, setVisites] = useState([]);
  const [ventes, setVentes] = useState([]);
  const [commandes, setCommandes] = useState([]);
  const list=[1,2,3,4,5,6,7,8,9,10,11,12]


  const valueFromList = (code, liste ) => {
    if (liste && liste.length !== 0) {
       
    const foundItem = liste.find(item => item.mois === code);
    
    return foundItem? foundItem.visite : 0;
    }else{
      return 0 ;
    }
   
  };
  const valueFromV_cmd= (code, liste ) => {
    if (liste && liste.length !== 0) {
       
    const foundItem = liste.find(item => item.mois === code);
    
    return foundItem? foundItem.total : 0;
    }else{
      return 0 ;
    }
   
  };

  useEffect(() => {
    getHistoriqueByMonth(code)
    .then((res) => {
      //Type Profile
      if (res.data.length !== 0) {
        setVisites(res.data.visite); 
        setVentes(res.data.total);
        setCommandes(res.data.commande);
       }
    })
    .catch((err) => console.log(err));

}, [])

  return (

    <div className="card table-responsive">
    <div className="card-header">
       
      <h4 className=" text-primary text-center mt-3 my-3 ">
      {t("suivi.Reportingvisites")}
        </h4>
    </div>
    <div className="d-flex mb-12">
      <div className="card-body">
      <h4>
     
      </h4>
      <div class="panel panel-white">

      <div className="table-responsive">
    <table
      className="table card-table table-striped tutorial-table"
      cellSpacing={0}
      width="100%"
    >
      <thead>
        <tr className="text-center">
          <th></th>
                 <th className="priority-1" width="15%"> {t("month.janvier")}</th>
                 <th className="priority-1" width="15%">{t("month.fevrier")}</th>
                 <th className="priority-1" width="15%">{t("month.mars")}</th>
                 <th className="priority-1" width="15%">{t("month.avril")}</th>
                 <th className="priority-1" width="15%">{t("month.mai")}</th>
                 <th className="priority-1" width="15%">{t("month.juin")}</th>
                 <th className="priority-1" width="15%">{t("month.juillet")}</th>
                 <th className="priority-1" width="15%">{t("month.aout")}</th>
                 <th className="priority-1" width="15%">{t("month.septembre")}</th>
                 <th className="priority-1" width="15%">{t("month.octobre")}</th>
                 <th className="priority-1" width="15%">{t("month.novembre")}</th>
                 <th className="priority-1" width="15%">{t("month.decembre")}</th>
         
        </tr>
      </thead>
      <tbody>
        
          <tr className="text-center"> 
            <td className="priority-1" width="15%">{t("client.nbVisite")}</td>
            {list.map((item)=>(
                <td  key={item} className="priority-1" width="15%">
              
                {valueFromList(item,visites)} 
                    </td>
            ))}
          {/*  <td className="priority-1" width="15%">
              
                  {valueFromList(1,visites)} 
                      </td>
                  <td className="priority-1" width="15%">  {visites[1]?.visite}
                 </td>
                  <td className="priority-1" width="15%">  {visites[2]?.visite}
                   </td>
                  <td className="priority-1" width="15%"> {visites[3]?.visite}
                  </td>
                  <td className="priority-1" width="15%"> {visites[4]?.visite}
                   </td>
                  <td className="priority-1" width="15%"> {visites[5]?.visite}
                   </td>
                  <td className="priority-1" width="15%"> {visites[6]?.visite}
                  </td>
                  <td className="priority-1" width="15%"> {visites[7]?.visite}
                   </td>
                  <td className="priority-1" width="15%"> {visites[8]?.visite}
                  </td>
                  <td className="priority-1" width="15%"> {visites[9]?.visite}
                   </td>
                  <td className="priority-1" width="15%"> {visites[10]?.visite}
                   </td>
                  <td className="priority-1" width="15%"> {visites[11]?.visite}
                  </td>
          */}
           
          </tr>

          <tr className="text-center"> 
            <td className="priority-1" width="15%">{t("client.TotalVente")}</td>
            {list.map((item)=>(
                <td  key={item} className="priority-1" width="15%">
              
                {valueFromV_cmd(item,ventes)} 
                    </td>
            ))}
                  {/*<td className="priority-1" width="15%">
                  {ventes[0]?.total}</td>
                  <td className="priority-1" width="15%">
                  {ventes[1]?.total}</td>
                  <td className="priority-1" width="15%">
                  {ventes[2]?.total}</td>
                  <td className="priority-1" width="15%">
                  {ventes[3]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[4]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[5]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[6]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[7]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[8]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[9]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[10]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[11]?.total}</td>

          */}
          </tr>

          <tr className="text-center"> 
            <td className="priority-1" width="15%">{t("commandes.totalcmd")}</td>
            {list.map((item)=>(
                <td  key={item} className="priority-1" width="15%">
              
                {valueFromV_cmd(item,commandes)} 
                    </td>
            ))}
            {/*
            <td className="priority-1" width="15%">
                  {ventes[0]?.total}</td>
                  <td className="priority-1" width="15%">
                  {ventes[1]?.total}</td>
                  <td className="priority-1" width="15%">
                  {ventes[2]?.total}</td>
                  <td className="priority-1" width="15%">
                  {ventes[3]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[4]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[5]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[6]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[7]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[8]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[9]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[10]?.total}</td>
                  <td className="priority-1" width="15%">
                 {ventes[11]?.total}</td>

          */}
          </tr>
        
      </tbody>
    </table>
   
  
  </div>

         
      </div>
      </div>
    </div>
  </div>

  );
}
