import axios from 'axios'

//GET URL FROM LOCALSTORAGE
const url = localStorage.getItem('url_nomadis')

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
  IsEssential: true,
})

/**
 * GET Rapports
 * @param {*}
 * @returns
 */
export async function getRapports(filtre) {
  try {
    const response = await instance.get(`/rapports/Rapport54?activite_code=&categoriecommercial_code=&date_debut=${filtre.datedebut}&date_fin=${filtre.datefin}&delegation_code=&region_code=&specialite=&type=${filtre.type}&user_code=${filtre.usercode}&zone_code=`)
    return response
  } catch (error) {
    console.error(error)
  }
}