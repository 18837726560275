/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import "../TodayCommercial/visites/map.css";
import {
  getVenteByMarques, getListMarque,
} from "../../services/clients/ClientApi";


export default function VenteParMarque({ code, t }) {

  const [marques, setMarques] = useState([]);
  const [ventes, setVentes] = useState([]);
  const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const [type, setType] = useState('tt')


  const getvaluefromList = (code, marque, liste) => {
    if (liste && liste.length !== 0) {

      const foundItem = liste.find(item => item.mois === code && item.libelle === marque);
      return foundItem ? type === 'tt'? foundItem.total  : foundItem.quantite: 0;
    } else {
      return 0;
    }

  };

  const sensvalue = (code, marque, liste) => {
    if (liste && liste.length !== 0) {

      const foundItem = liste.find(item => item.mois === code && item.libelle === marque);
      const foundItem_pres = liste.find(item => item.mois === (code - 1) && item.libelle === marque);

      const total_code = foundItem ? type === 'tt'? foundItem.total  : foundItem.quantite: 0;
      const total_code_pres = foundItem_pres ?type === 'tt'? foundItem_pres.total  : foundItem_pres.quantite : 0;
      if (total_code > total_code_pres) {
        return 1
      } else if (total_code < total_code_pres) {
        return -1
      } else {
        return 0

      }

    }
  }

  useEffect(() => {
    getVenteByMarques(code)
      .then((res) => {
        //Type Profile
        if (res.data.length !== 0) {
          setVentes(res.data);
        }
      })
      .catch((err) => console.log(err));


    getListMarque(code)
      .then((res) => {

        if (res.data.length !== 0) {
          setMarques(res.data);
        }
      })
      .catch((err) => console.log(err));

  }, [])

  const onChangeType =()=>{
    setType(type ==='Qte'?'tt':'Qte')
  }
  return (

    <div className="card table-responsive">
      <div className="card-header">

        <h4 className=" text-primary text-center mt-3 my-3 ">
          {t("suivi.ventemarque")}
        </h4>
      </div>
      <div className="d-flex mb-12">
        <div className="card-body">
          <h4>

          </h4>
          <div class="panel panel-white">

            <div className="table-responsive">
              <button className="btn bg-primary "
                onClick={ onChangeType}
              >{type !== 'Qte' ? "Quantite" : 'Chiffre'}</button>
              <table
                className="table card-table table-striped tutorial-table"
                cellSpacing={0}
                width="100%"
              >
                <thead>
                  <tr className="text-center">
                    <th></th>
                    <th className="priority-1" width="15%"> {t("month.janvier")}</th>
                    <th className="priority-1" width="15%">{t("month.fevrier")}</th>
                    <th className="priority-1" width="15%">{t("month.mars")}</th>
                    <th className="priority-1" width="15%">{t("month.avril")}</th>
                    <th className="priority-1" width="15%">{t("month.mai")}</th>
                    <th className="priority-1" width="15%">{t("month.juin")}</th>
                    <th className="priority-1" width="15%">{t("month.juillet")}</th>
                    <th className="priority-1" width="15%">{t("month.aout")}</th>
                    <th className="priority-1" width="15%">{t("month.septembre")}</th>
                    <th className="priority-1" width="15%">{t("month.octobre")}</th>
                    <th className="priority-1" width="15%">{t("month.novembre")}</th>
                    <th className="priority-1" width="15%">{t("month.decembre")}</th>

                  </tr>
                </thead>
                <tbody>

                  {marques.map((item) => (
                    <tr className="text-center">
                      <th className="priority-1" width="15%">{item.libelle}</th>

                      <td className="priority-1" width="15%">{getvaluefromList(1, item.libelle, ventes)} </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(2, item.libelle, ventes) !== 0 ?
                          sensvalue(2, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""} 
                        {getvaluefromList(2, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(3, item.libelle, ventes) !== 0 ?
                          sensvalue(3, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(3, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(4, item.libelle, ventes) !== 0 ?
                          sensvalue(4, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(4, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(5, item.libelle, ventes) !== 0 ?
                          sensvalue(5, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(5, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(6, item.libelle, ventes) !== 0 ?
                          sensvalue(6, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(6, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(7, item.libelle, ventes) !== 0 ?
                          sensvalue(7, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(7, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(8, item.libelle, ventes) !== 0 ?
                          sensvalue(8, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(8, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(9, item.libelle, ventes) !== 0 ?
                          sensvalue(9, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(9, item.libelle, ventes)}
                      </td>
                      <td className="priority-1" width="15%">
                        {sensvalue(10, item.libelle, ventes) !== 0 ?
                          sensvalue(10, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(10, item.libelle, ventes)}</td>
                      <td className="priority-1" width="15%">
                        {sensvalue(11, item.libelle, ventes) !== 0 ?
                          sensvalue(11, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(11, item.libelle, ventes)}</td>
                      <td className="priority-1" width="15%">
                        {sensvalue(12, item.libelle, ventes) !== 0 ?
                          sensvalue(12, item.libelle, ventes) === 1 ?
                            <i className="ti-arrow-up" style={{ color: "green" }} /> :
                            <i className="ti-arrow-down " style={{ color: "red" }} /> : ""}
                        {getvaluefromList(12, item.libelle, ventes)}</td>

                    </tr>
                  ))}


                </tbody>
              </table>


            </div>


          </div>
        </div>
      </div>
    </div>

  );
}
