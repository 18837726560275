import React from "react";
import { Route, useHistory } from "react-router-dom";
import { isLogin } from "../utils/SessionUtils";

export default function PrivateRoute({
  component: Component,
  layout: Layout,
  ...rest
}) {
const history = useHistory()
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          {true ? <Component {...props}></Component> :<>{ history.push({pathname:'/code'})}</>}
        </Layout>
      )}
    ></Route>
  );
}
