/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';

import { useTranslation } from "react-i18next";
import { getColonne, getLignes, saveVeille } from "../../services/Veille/VeilleApi";
import { toast, ToastContainer } from "react-toastify";
import { map } from "underscore";

export default function Veille() {
    const [t] = useTranslation("global");
    const [lignesdata, setlignesdata] = useState([])
    const [P_concurrents, setP_concurrents] = useState([])
    const [listgroupe, setlistgroupe] = useState([])
    const listtype_client = ['Ancien actif', ' Ancien relancé', 'Nouveau']


    useEffect(() => {
        getColonne().then(rescolone => {
            if (rescolone.status === 200) {

                setP_concurrents(rescolone.data)
                let list_groupe = new Set(rescolone.data.map(item => item.groupe));
                setlistgroupe([...list_groupe])

            }

        }


        )
        getLignes().then((reslignes) => {
            if (reslignes.status === 200) {
                setlignesdata(reslignes.data)
            }

        })
    }, [])


    const [entet, setentet] = useState({
        type_client: 'Nouveau',
        livraison_jour: 1,
        paiement_facture_jour: "Comptantespeces",
        paiement_ancienne_facture: "Neant"
    })
    const [listselected, setlistselected] = useState([])
    const handlchange_entet = (name, value) => {
        const copie_data = { ...entet }
        copie_data[name] = value;
        setentet(copie_data)
    }
    const change_table = (ligne, P_concurrents) => {
        const copie_data = [...listselected]
        const findselected_index = listselected.findIndex(item => item.code_ligne === ligne && item.code_colonne === P_concurrents);

        if (findselected_index === -1) {
            // ajouter

            const data = {
                client_code: localStorage.getItem('codeclient'),
                code_colonne: P_concurrents,
                code_ligne: ligne,
                reponse: 1,
                user_code: JSON.parse(localStorage.getItem('authentification_nomadis')).user.code
            }
            copie_data.push(data)
        } else {
            copie_data.splice(findselected_index, 1);


        }

        setlistselected(copie_data)
    }
    const save = async () => {

        /*  const promises = listselected.map(element => saveVeille(element));
  
          Promise.all(promises)
              .then(results => {
                  results.forEach(resp => {
                      console.log('data2resp', resp);
                  });
              })
              .catch(error => {
                  // Handle error if any of the promises fail
                  console.error('Error occurred:', error);
              });*/
        const client_code = localStorage.getItem('codeclient');
        const randomNum = Math.floor(Math.random() * 1000);
        const dateString = new Date().toISOString().replace(/[-:T.]/g, "").slice(0, 14);

        const code = `${client_code}_${dateString}_${randomNum}`;

        const listdata = listselected.map(element => {
            const copie = { ...element, ...entet }
            copie.code = code
            copie.code_colonne = parseInt(copie.code_colonne)
            copie.code_ligne = parseInt(copie.code_ligne)
            return copie
        });
        const datasend = JSON.stringify({ veuilles: listdata })

        const res = await saveVeille({ veuilles: listdata })


        if (res.status == 200) {

            toast(t('données enregistré avec succès'), {
                type: "success",
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            toast(t('un problème'), {
                type: "error",
                position: toast.POSITION.BOTTOM_RIGHT,
            });


        }


    }



    const exportToExcel = () => {
        // Create a new workbook
        let wb = XLSX.utils.book_new();

        // Initialize the main sheet
        let mainWsData = [];
        let mainHeaders = ['type_client', 'livraison_jour', 'paiement_facture_jour', 'paiement_ancienne_facture'];
        mainWsData.push(mainHeaders);

        let mainRow = [
            entet.type_client,
            entet.livraison_jour,
            entet.paiement_facture_jour,
            entet.paiement_ancienne_facture
        ];
        mainWsData.push(mainRow);

        // Create the main worksheet
        let mainWs = XLSX.utils.aoa_to_sheet(mainWsData);
        XLSX.utils.book_append_sheet(wb, mainWs, 'f1');

        // Loop through each group to create sheets
        listgroupe.forEach(gp => {
            let groupeData = lignesdata.filter(item => item.groupe === gp);
            let groupeWsData = [];
            let groupeHeaders = [''];

            P_concurrents.filter(item => item.groupe === gp).forEach(item => {
                groupeHeaders.push(item.libelle);
            });
            groupeWsData.push(groupeHeaders);

            groupeData.forEach(cl => {
                let row = [cl.libelle];
                P_concurrents.filter(item => item.groupe === gp).forEach(p => {
                    let isChecked = listselected.find(item => item.code_ligne === cl.code && item.code_colonne === p.code);
                    row.push(isChecked ? 'oui' : 'non');
                });
                groupeWsData.push(row);
            });

            // Create and append the group worksheet
            let groupeWs = XLSX.utils.aoa_to_sheet(groupeWsData);
            XLSX.utils.book_append_sheet(wb, groupeWs, gp.toString());
        });

        // Finalize and trigger the download
        XLSX.writeFile(wb, 'ExportedData.xlsx');
    };






    return (

        <><ToastContainer /><div className="card">

            <div className="card-body">


                <div className="form-group">

                    <label htmlFor="exampleInputname1">{t('Type Client')}</label>
                    <select className="form-control text-primary select2 w-100"
                        value={entet.value}
                        onChange={(e) => handlchange_entet('type_client', e.target.value)}
                    >
                        {listtype_client.map((item, index) => (
                            <option key={index} value={item}>
                                {item}
                            </option>
                        ))}

                    </select>

                </div>

                <div>
                    {listgroupe.map(gp => (
                        <div className="row table-responsive">
                            <table className="table table-bordered-black card-table table-striped tutorial-table" cellSpacing={0} width="100%">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {P_concurrents.filter(item => item.groupe === gp).map((item, index) => (
                                            <th key={index} value={item.code}>{item.libelle}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {lignesdata.filter(item => item.groupe === gp).map((cl, index) => (
                                        <tr key={index}>
                                            <td value={cl.code}>{cl.libelle}</td>
                                            {P_concurrents.filter(item => item.groupe === gp).map((p, i) => (
                                                <td key={i}>
                                                    <input type='checkbox' className="form-control fixed-width" onClick={() => change_table(cl.code, p.code)} checked={listselected.find(item => item.ligne === cl.code && item.P_concurrents === p.code)} />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                    ))}
                    <div className="row">
                        <button onClick={exportToExcel} className="btn btn-primary col-12">
                            Exporter en Excel
                        </button>
                    </div>


                </div>





                <label className=" row mt-5">{t('Livraison du jour')}</label>

                <div className="row">
                    <div className="col-12 col-md-3 d-flex align-items-center">
                        <input
                            type="radio"
                            id="oui"
                            name="livraison_jour"
                            checked={entet.livraison_jour === 1}
                            onClick={() => handlchange_entet('livraison_jour', 1)}
                            className="col-2 col-md-3 text-primary " />
                        <label htmlFor="oui" className="ml-2">Oui</label>
                    </div>
                    <div className="w-100 d-md-none"></div> {/* Line break for small screens */}
                    <div className="col-12 col-md-3 d-flex align-items-center mt-2 mt-md-0">
                        <input
                            type="radio"
                            id="non"
                            name="livraison_jour"
                            checked={entet.livraison_jour === 0}
                            onClick={() => handlchange_entet('livraison_jour', 0)}
                            className="col-2 col-md-3 text-primary" />
                        <label htmlFor="non" className="ml-2">Non</label>
                    </div>
                </div>



                <label className="row mt-5">{t('Paiement facture du jour')}</label>
                <div className="row">
                    <div className="col-12 col-md-3 d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Comptantespeces"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'Comptantespeces'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'Comptantespeces')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Comptantespeces" className="ml-2">Comptant espèces</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Comptantcheque"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'Comptantcheque'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'Comptantcheque')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Comptantcheque" className="ml-2">Comptant chèque</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Chequeantidate"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'Chequeantidate'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'Chequeantidate')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Chequeantidate" className="ml-2">Chèque anti daté</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center">
                        <input
                            type="radio"
                            id="credit"
                            name="paiement_facture_jour"
                            checked={entet.paiement_facture_jour === 'credit'}
                            onClick={() => handlchange_entet('paiement_facture_jour', 'credit')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="credit" className="ml-2">À crédit</label>
                    </div>
                </div>




                <label className=" row mt-5">{t('Paiement ancienne facture')}</label>
                <div className="row mt-5">
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Neant"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Neant'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Neant')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Neant" className="ml-2">Néant</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Especes"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Especes'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Especes')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Especes" className="ml-2">Espèces</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center mb-2 mb-md-0">
                        <input
                            type="radio"
                            id="Chequeantidate"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Chequeantidate'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Chequeantidate')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Chequeantidate" className="ml-2">Cheque anti daté</label>
                    </div>
                    <div className="w-100 d-md-none"></div>
                    <div className="col-12 col-md-3  d-flex align-items-center">
                        <input
                            type="radio"
                            id="Cheque"
                            name="paiement_ancienne_facture"
                            checked={entet.paiement_ancienne_facture === 'Cheque'}
                            onClick={() => handlchange_entet('paiement_ancienne_facture', 'Cheque')}
                            className="col-2 col-md-1 text-primary" />
                        <label htmlFor="Cheque" className="ml-2">Cheque</label>
                    </div>
                </div>

                <div>
                    <button style={{ float: 'right' }} className="btn bg-primary mt-5 " onClick={save}>save</button>
                </div>


            </div>
        </div></>


    )
}
{/*  <tr>
                                        <td>{t('Abri')}</td>
                                        {P_concurrents.filter(item=>item.groupe === gp).map((p, i) => (
                                            <td key={i} className="fixed-width">
                                                <input type='checkbox' className="form-control fixed-width" onClick={() => change_table('Abri', p)} checked={listselected.find(item => item.ligne === 'Abri' && item.P_concurrents === p)} />
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{t('Promo')}</td>
                                        {P_concurrents.map((p, i) => (
                                            <td key={i} className="fixed-width">
                                                <input onClick={() => change_table('Promo', p)} checked={listselected.find(item => item.ligne === 'Promo' && item.P_concurrents === p)} type='checkbox' className="form-control fixed-width" />
                                            </td>
                                        ))}
                                    </tr>*/}