/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ErrorAlert from "../../others/ErrorAlert";
import { getListActivity, getListCategorie, updateClientByCode } from "../../services/clients/ClientApi";
import { getListRouting } from "../../services/commande/commandeApi";
import { Route, useHistory } from "react-router-dom";

export default function CardEditShow({ users, code, t }) {
  const history = useHistory()

  //SHOW ELEMENTS
  const [show, setShow] = useState(false);
  //SHOW VISITES
  // const [showV, setShowV] = useState(false);
  //Liste Routings
  const [listRoutings, setListRouting] = useState([]);
  //List catégories
  const [listCatégories, setListCategorie] = useState([]);
  //lISTE DES ACTIVITES
  const [listActivite, setListActivite] = useState([])
  //ERROR
  const [error, setError] = useState(false);
  /**DATA FORMULAIRE */

  //Autorisation credit + cheque + TRAITE
  const [autorisation, setAutorisation] = useState(users.autorisation)
  const [autorisationCheque, setAutorisationCheque] = useState(users.autorisation_cheque)
  const [autorisationTraite, setAutorisationTraite] = useState(users.autorisation_traite)
  //Plafond credit + cheque + traite
  const [plafondCredit, setPlafondCredit] = useState(users.plafond_credit)
  const [plafondCheque, setPlafondCheque] = useState(users.plafond_credit_cheque)
  const [plafondTraite, setplafondTraite] = useState(users.plafond_credit_traite)
  // INFORMATIONS CLIENTS
  const [categorie, setCategorie] = useState(users.categoriecommercial_code)
  const [bloque, setBloque] = useState(users.bloque)
  const [isActif, setIsActif] = useState(users.isactif)
  const [activite, setActivite] = useState(users.activite)
  const [mobile, setMobile] = useState(users.mobile)
  //Tresorerie
  const [delaisPaiement, setDelaisPaiement] = useState(users.delai_paiement)
  const [tarification, setTarification] = useState(users.tarification)
  //Adresses
  const [zone, setZone] = useState(users.zone)
  const [region, setRegion] = useState(users.region)
  const [adresse, setAdresse] = useState(users.adresse_facturation)
  const [longitude, setlongitude] = useState(users.longitude)
  const [latitude, setlatitude] = useState(users.latitude)

  //console.log(users)


  // AUTORISATION / BLOQUER LA MODIFICATION
  const handleChange = () => {
    setShow(!show);
  };

  // MODIFIER LES INFORMATIONS DU CLIENTS
  const updateClient = () => {
    const data = {
      categorie: categorie,
      bloque: bloque,
      isactif: isActif,
      zone: zone,
      region: region,
      activite: activite,
      adresse: adresse,
      plafondCredit: plafondCredit,
      plafondCheque: plafondCheque,
      plafondTraite: plafondTraite,
      delaisPaiement: delaisPaiement,
      autorisation: autorisation,
      autorisationCheque: autorisationCheque,
      autorisationTraite: autorisationTraite,
      longitude: longitude,
      latitude: latitude,
      tarification: tarification,
      mobile: mobile,
    }
    updateClientByCode(code, data)
      .then((res) => {
        if (res === false) {
          toast(t('widget.cnxinternet'), { position: 'bottom-center', type: "warning" })
          setError(true)
        }
        else if (res.data.status === true) {
          toast(t('messages.modifiee'), {
            type: "success",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setShow(false);
          window.location.reload();
        } else {
          toast(t('messages.erreur'), {
            type: "error",
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((error) => setError(true));
  };
  useEffect(() => {
    //Liste Routing Clients
    getListRouting()
      .then((res) => {
        if (res?.data) {
          setListRouting(res.data);
        }
      })
      .catch((error) => console.error(error));

    //get list categorie
    getListCategorie()
      .then((res) => {
        if (res?.data) {
          setListCategorie(res.data);
        }
      })
      .catch((error) => console.error(error));
    //GET LIST ACTIVITE
    getListActivity().then((res) => {
      if (res?.data) {
        setListActivite(res.data);
      }
    })
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <ToastContainer />

      <div className="card">
        <div className="card-header">
          <h3 className="card-title float-left mt-2 my-2 ">
            {t("detailClient.informationsClients")}
          </h3>

          <button
            className="btn btn-secondary btn-icon-text float-right"
            value={show}
            onClick={() => handleChange()}
          >
            <i className="ti-eye" />
          </button>
          <h6 className="text-wrap float-right mt-2 my-2 mr-2">
            {t('detailClient.datecreation')}   {users.date_creation}
          </h6>
        </div>
        {/** EDITABLE MODE (NOT DISABLED) */}
        <div className="card-body">
          {show ? (
            <div key={"edit"}>

              {/**Edit EN Cours / MOBILE / Routing */}
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('tournee.categorie')}
                    </label>
                    <select
                      className="form-control text-primary select2 w-100"
                      value={categorie}
                      onChange={(e) => setCategorie(e.target.value)}
                    >
                      {listCatégories?.map((items, keys) => (
                        <option key={keys} value={items.code}>
                          {items.code} : {items.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.activite')}
                    </label>
                    <select
                      className="form-control text-primary select2 w-100"
                      value={activite}
                      onChange={(e) => setActivite(e.target.value)}
                    >
                      {listActivite?.map((items, keys) => (
                        <option key={keys} value={items.code}>
                          {items.code} : {items.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.coderouting")}
                    </label>
                    <select
                      className="form-control text-primary select2 w-100"
                      value={users.routing_code}
                      disabled
                    >
                      {listRoutings?.map((items, keys) => (
                        <option key={keys} value={items.code}>
                          {items.code} : {items.dist}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

              </div>
              {/**Edit Autorisation / Plafond */}

              <div className="row">
                {/**Autorisation Crédit */}
                <div className="col-lg-4 col-md-4">
                  {/* Autorisation */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname">
                      {t("detailClient.autorisationcredit")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={autorisation}
                      onChange={(e) =>
                        setAutorisation(e.target.value)
                        /*  setData({ autorisation: e.target.value }) */
                      }
                    >
                      <option value={0}>{t("select.non")}</option>
                      <option value={1}>{t("select.oui")}</option>
                    </select>
                  </div>
                  {/* Plafond */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname">
                      {t("detailClient.plafondcredit")}
                    </label>
                    {autorisation === "1" ? (
                      <input
                        type="text"
                        className="form-control text-primary"
                        id="exampleInputname"
                        placeholder={t("detailClient.plafondcredit")}
                        value={plafondCredit}
                        onChange={(e) =>
                          setPlafondCredit(e.target.value)}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control text-primary"
                        id="exampleInputname"
                        placeholder={t("detailClient.plafondcredit")}
                        value={plafondCredit}
                        disabled
                      />
                    )}
                  </div>
                </div>

                {/**Autorisation Chéque */}
                <div className="col-lg-4 col-md-4">
                  {/* Autorisation */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.autorisationcheque")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={autorisationCheque}
                      onChange={(e) =>
                        setAutorisationCheque(e.target.value)
                        /* setData({ autorisationCheque: e.target.value })*/
                      }
                    >
                      <option value={0}>{t("select.non")}</option>
                      <option value={1}>{t("select.oui")}</option>
                    </select>
                  </div>
                  {/* Plafond */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.plafondcheque")}
                    </label>
                    {autorisationCheque === "1" ? (
                      <input
                        type="text"
                        className="form-control text-primary"
                        id="exampleInputname1"
                        value={plafondCheque}
                        onChange={(e) =>
                          setPlafondCheque(e.target.value)
                        }
                        placeholder={t("detailClient.plafondcheque")}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control text-primary"
                        id="exampleInputname1"
                        value={plafondCheque}
                        disabled
                        placeholder={t("detailClient.plafondcheque")}
                      />
                    )}
                  </div>
                </div>
                {/**Autorisation Traite */}
                <div className="col-lg-4 col-md-4">
                  {/* Autorisation */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname ">
                      {t("detailClient.autorisationtraite")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={autorisationTraite}
                      onChange={(e) =>
                        setAutorisationTraite(e.target.value)
                      } >
                      <option value={null}>{t("select.non")}</option>
                      <option value={1}>{t("select.oui")}</option>
                    </select>
                  </div>
                  {/* Plafond */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname ">
                      {t("detailClient.plafondtraite")}
                    </label>
                    {autorisationTraite === "1" ? (
                      <input
                        type="text"
                        className="form-control text-primary"
                        id="exampleInputname"
                        placeholder={t("detailClient.plafondtraite")}
                        value={
                          plafondTraite === null
                            ? "00"
                            : plafondTraite
                        }
                        onChange={(e) =>
                          setplafondTraite(e.target.value)
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control text-primary"
                        id="exampleInputname"
                        placeholder={t("detailClient.plafondtraite")}
                        value={
                          plafondTraite === null
                            ? "00"
                            : plafondTraite
                        }
                        disabled
                      />
                    )}
                  </div>
                </div>
              </div>
              {/** Bloquer / ACTIF */}
              <div className="row mt-2">
                {/**Code TOURNEE BLOC */}
                <div className="col-sm-6 ">
                  <div className="description-block">
                    <span className="text-muted ">{t("detailClient.actif")}</span>
                    <h5 className="description-header text-primary">
                      {/**CODE TOURNEE */}
                      <select
                        className="form-control text-primary select2 w-100"
                        value={isActif}
                        onChange={(e) => setIsActif(e.target.value)}
                      >
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                      </select>
                    </h5>
                  </div>
                </div>
                {/**ROUTING CODE BLOC */}
                <div className="col-sm-6 ">
                  <div className="description-block">
                    <span className="text-muted ">{t("detailClient.bloquer")}</span>
                    <h5 className="description-header text-primary">
                      {/** Routing CODE */}
                      <select
                        className="form-control text-primary select2 w-100"
                        value={bloque}
                        onChange={(e) => setBloque(e.target.value)}
                      >
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                      </select>
                    </h5>
                  </div>
                </div>
              </div>
              {/** Adresse / Long / LAT */}
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.zone')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={zone}
                      onChange={(e) => setZone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.region')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}

                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.longitude")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={longitude}
                      onChange={(e) => setlongitude(e.target.value)}
                      placeholder={t("detailClient.longitude")}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.latitude")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={latitude}
                      onChange={(e) => setlatitude(e.target.value)}
                      placeholder={t("detailClient.latitude")}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.adresseFacturation")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={adresse}
                      onChange={(e) => setAdresse(e.target.value)}
                      placeholder="adresse_facturation"
                    />
                  </div>
                </div>


                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.delaipaieemnt")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={delaisPaiement}
                      onChange={(e) =>
                        setDelaisPaiement(e.target.value)
                      }
                      placeholder={t("detailClient.delaipaieemnt")}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.tarification")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={tarification}
                      onChange={(e) => setTarification(e.target.value)}
                    >
                      <option value={"tarif"}>tarif</option>
                      <option value={"tarif1"}>tarif 1</option>
                      <option value={"tarif2"}>tarif 2</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.mobile")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      placeholder={t("detailClient.mobile")}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.codesms')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={users.code_sms === null ? t('detailClient.aucun') : users.code_sms}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.remiseclient')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={users.remise_client}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div key={"show"}>
              {/**Edit EN Cours / MOBILE / Routing */}
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('tournee.categorie')}
                    </label>
                    <select
                      className="form-control text-primary select2 w-100"
                      value={categorie}
                      disabled
                    >
                      {listCatégories?.map((items, keys) => (
                        <option key={keys} value={items.code}>
                          {items.code} : {items.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.activite')}
                    </label>
                    <select
                      className="form-control text-primary select2 w-100"
                      value={activite}
                      disabled
                    >
                      {listActivite?.map((items, keys) => (
                        <option key={keys} value={items.code}>
                          {items.code} : {items.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.coderouting")}
                    </label>
                    <select
                      className="form-control text-primary select2 w-100"
                      value={users.routing_code}
                      disabled
                    >
                      {listRoutings?.map((items, keys) => (
                        <option key={keys} value={items.code}>
                          {items.code} : {items.dist}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

              </div>
              {/**Edit Autorisation / Plafond */}

              <div className="row">
                <div className="col-lg-4 col-md-4">
                  {/* Autorisation */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname">
                      {t("detailClient.autorisationcredit")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={autorisation}
                      disabled
                    >
                      <option value={0}>{t("select.non")}</option>
                      <option value={1}>{t("select.oui")}</option>
                    </select>
                  </div>
                  {/* Plafond */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname">
                      {t("detailClient.plafondcredit")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname"
                      placeholder={t("detailClient.plafondcredit")}
                      value={plafondCredit}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  {/* Autorisation */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.autorisationcheque")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={autorisationCheque}
                      disabled
                    >
                      <option value={0}>{t("select.non")}</option>
                      <option value={1}>{t("select.oui")}</option>
                    </select>
                  </div>
                  {/* Plafond */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.plafondcheque")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={plafondCheque}
                      disabled
                      placeholder={t("detailClient.plafondcheque")}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  {/* Autorisation */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname ">
                      {t("detailClient.autorisationtraite")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={autorisationTraite}
                      disabled
                    >
                      <option value={null}>{t("select.non")}</option>
                      <option value={1}>{t("select.oui")}</option>
                    </select>
                  </div>
                  {/* Plafond */}
                  <div className="form-group">
                    <label htmlFor="exampleInputname ">
                      {t("detailClient.plafondtraite")}
                    </label>

                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname"
                      placeholder={t("detailClient.plafondtraite")}
                      value={
                        plafondTraite === null ? "00" : plafondTraite
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {/**Code TOURNEE BLOC */}
                <div className="col-sm-6 ">
                  <div className="description-block">
                    <span className="text-muted ">{t("detailClient.actif")}</span>
                    <h5 className="description-header text-primary">
                      {/**CODE TOURNEE */}
                      <select
                        className="form-control text-primary select2 w-100"
                        value={isActif}
                        disabled
                      >
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                      </select>
                    </h5>
                  </div>
                </div>
                {/**ROUTING CODE BLOC */}
                <div className="col-sm-6 ">
                  <div className="description-block">
                    <span className="text-muted ">{t("detailClient.bloquer")}</span>
                    <h5 className="description-header text-primary">
                      {/** Routing CODE */}
                      <select
                        className="form-control text-primary select2 w-100"
                        value={bloque}
                        disabled
                      >
                        <option value={1}>Oui</option>
                        <option value={0}>Non</option>
                      </select>
                    </h5>
                  </div>
                </div>
              </div>
              {/** Adresse / Long / LAT */}
              <div className="row">
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.zone')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={zone}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.region')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={region}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.longitude")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={longitude}
                      disabled
                      placeholder={t("detailClient.longitude")}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.latitude")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={latitude}
                      disabled
                      placeholder={t("detailClient.latitude")}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.adresseFacturation")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={adresse}
                      disabled
                      placeholder="adresse_facturation"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.delaipaieemnt")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={delaisPaiement}
                      disabled
                      placeholder={t("detailClient.delaipaieemnt")}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.tarification")}
                    </label>
                    <select
                      className="form-control select2 text-primary w-100"
                      value={tarification}
                      disabled
                    >
                      <option value={"tarif"}>tarif</option>
                      <option value={"tarif1"}>tarif 1</option>
                      <option value={"tarif2"}>tarif 2</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t("detailClient.mobile")}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      value={mobile}
                      placeholder={t("detailClient.mobile")}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.codesms')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={users.code_sms === null ? t('detailClient.aucun') : users.code_sms}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputname1">
                      {t('detailClient.remiseclient')}
                    </label>
                    <input
                      type="text"
                      className="form-control text-primary"
                      id="exampleInputname1"
                      value={users.remise_client}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
         

          
          {show ? (
            <button
              className="btn btn-success mr-1 mx-1 float-right"
              onClick={() => updateClient()}
            >
              <i className="ti-pencil-alt btn-icon-text mr-1" />{" "}
              {t("detailClient.modifier")}
            </button>
          ) : (
            <button className="btn btn-success mr-1 mx-1 float-right" disabled>
              <i className="ti-pencil-alt btn-icon-text mr-1" />{" "}
              {t("detailClient.modifier")}
            </button>
          )}
          {/*  <button
            className="btn btn-secondary mr-1 mx-1 float-right"
            onClick={() => handleChange2()}
          >
            <i className="fe fe-users btn-icon-text mr-1" /> {t("detailClient.derniereVisite")}
          </button> */}
        </div>
        {error ? <ErrorAlert /> : <></>}
      </div>
      {/** ROW */}
    </>
  );
}
