import React, { useEffect, useState } from 'react'
import { getNomCommercials } from '../../services/Reporting/ReportingApi';
import { getRapports } from '../../services/service/service';
import { formatdate } from '../../others/helper';
import MapComponent from './MapComponent';
import { Spinner } from 'react-bootstrap';

export default function Rapport() {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    const [loading,setLoading]=useState(false)

    const [filtre, setFiltre] = useState({
        datedebut: formatdate(lastMonth),
        datefin: formatdate(today),
        type: 'bl',
        usercode: ""
    })

    const [liste, setListe] = useState([])
    const [listeCommercial, setListeCommercial] = useState([])

    useEffect(() => {
        getNomCommercials().then((res) => {

            if (res?.data !== null) {
                setListeCommercial(res?.data)
                setFiltre({ ...filtre, usercode: res?.data[0].code });

            }
        })

    }, []);
  
    const findRapports =()=>{
        setLoading(true)
        getRapports(filtre).then((res) => {
            if (res?.data) {
                setListe(res.data)
            }
            setLoading(false)
        })
    }

    return (
        <div className='card'>
            <div className=' card-body'>
                <div className='row'>
                    <div className='col-2'>
                        <label>Type</label>
                        <select
                            className="form-control"
                            value={filtre.type}
                            onChange={(e) => {
                                setFiltre({ ...filtre, type: e.target.value });
                            }}
                        >
                            <option value='bl'>BL</option>
                            <option value='commande'>Commande</option>

                        </select>
                    </div>
                    <div className='col-2'>
                        <label>Commercial</label>
                        <select
                            className="form-control"
                            value={filtre.usercode}
                            onChange={(e) => {
                                setFiltre({ ...filtre, usercode: e.target.value });
                            }}
                        >
                            {listeCommercial?.map((items, keys) => (
                                <option value={items.code} key={keys}> {items.user} </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-3'>
                        <label>Date debut</label>
                        <input type='date' className="form-control"
                            value={filtre.datedebut} onChange={(e) => {
                                setFiltre({ ...filtre, datedebut: e.target.value });
                            }} />
                    </div>
                    <div className='col-3'>
                        <label>Date fin</label>
                        <input type='date' className="form-control"
                            value={filtre.datefin} onChange={(e) => {
                                setFiltre({ ...filtre, datefin: e.target.value });
                            }} />
                    </div>
                    <div className='col-2 mt-6'>
                            <button className='btn btn-primary ' onClick={findRapports}>chercher</button>
                    </div>
                </div>
               
            </div>
            {loading?(
                        <Spinner/>
                ):
                <MapComponent Clients={liste.clients} ventes={ liste?.lastbls? Object.values(liste?.lastbls):[]}/>
            }
        </div>

    )
}
