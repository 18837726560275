/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function TopNavbar() {
  const [t, i18n] = useTranslation("global");
  const today = moment().format("YYYY-MM-DD")
  const month = moment().format("YYYY-MM")
  return (
    <div className="horizontal-main hor-menu clearfix">
      <div className="horizontal-mainwrapper container clearfix">
        <nav className="horizontalMenu clearfix">
          <ul className="horizontalMenu-list">
            <li aria-haspopup="true">
              <Link to="/" /* className="sub-icon  active" */>
                <i className="ti-home mr-2"/>
                {t("dashboard.libelle")}
              </Link>
            </li>
            <li aria-haspopup="true">
              <Link to="#" className="sub-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Capa_1"
                  className="icon_img"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <path
                      d="m472 216.061v-100c0-22.056-17.944-40-40-40h-352c-22.056 0-40 17.944-40 40v200c0 22.056 17.944 40 40 40h352c22.056 0 40-17.944 40-40 0-11.046 8.954-20 20-20s20 8.954 20 20c0 44.112-35.888 80-80 80h-156v39.878h70c11.046 0 20 8.954 20 20s-8.954 20-20 20h-180c-11.046 0-20-8.954-20-20s8.954-20 20-20h70v-39.878h-156c-44.112 0-80-35.888-80-80v-200c0-44.112 35.888-80 80-80h352c44.112 0 80 35.888 80 80v100c0 11.046-8.954 20-20 20s-20-8.954-20-20zm-82-71h-119c-11.046 0-20 8.954-20 20s8.954 20 20 20h119c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-163.261-39.419c-8.515-7.035-21.121-5.835-28.157 2.68l-43.205 52.293-17.048-17.507c-7.708-7.915-20.369-8.083-28.282-.376-7.914 7.706-8.083 20.368-.376 28.282l21.454 22.033c.177.182.357.36.541.534 6.483 6.17 15.136 9.604 24.038 9.604.765 0 1.532-.025 2.3-.077 9.644-.643 18.65-5.31 24.737-12.811l46.678-56.497c7.035-8.516 5.835-21.122-2.68-28.158zm163.261 162.419h-119c-11.046 0-20 8.954-20 20s8.954 20 20 20h119c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-163.261-39.543c-8.515-7.035-21.121-5.835-28.157 2.68l-43.216 52.307-17.076-17.438c-7.729-7.893-20.391-8.026-28.283-.296-7.892 7.728-8.024 20.391-.296 28.283l21.454 21.909c.164.167.331.332.5.493 6.483 6.171 15.136 9.604 24.039 9.604.765 0 1.533-.026 2.3-.077 9.644-.643 18.65-5.31 24.737-12.811l46.678-56.497c7.035-8.515 5.835-21.121-2.68-28.157z"
                      data-original="#000000"
                      className="active-path"
                      data-old_color="#000000"
                      fill="#FFFFFF"
                    />
                  </g>
                </svg>
                {t("commandes.title")}
               <i className="fa fa-angle-down horizontal-icon" />
              </Link>
              <ul className="sub-menu">
                <li aria-haspopup="true">
                  <a href="/Aujourdhui_commandes" className="slide-item">
                  {t("commandes.today")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href="/commandes" className="slide-item">
                  {t("suivi.doneecommandes")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href={"/gestion_commandes/date="+today} className="slide-item">
                  {t("commandes.suivi_cmd")}
                  </a>
                </li>
              </ul>
            </li>
            <li aria-haspopup="true">
              <a href="#" className="sub-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  className="icon_img"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.001 512.001"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <g>
                        <path
                          d="M507.519,116.384C503.721,111.712,498.021,109,492,109h-7.208L381.65,5.858c-7.811-7.811-20.475-7.811-28.285,0    l-43.359,43.359l-43.36-43.359c-7.811-7.811-20.475-7.811-28.285,0L135.219,109h-5.483l-1.484-13.632l-0.053-0.438    C121.099,40.811,74.583,0,20,0C8.954,0,0,8.954,0,20s8.954,20,20,20c34.506,0,63.923,25.749,68.512,59.928l23.775,218.42    C91.5,327.785,77,348.724,77,373c0,0.169,0.021,0.332,0.025,0.5C77.021,373.668,77,373.831,77,374c0,33.084,26.916,60,60,60h8.157    c-2.036,5.967-3.157,12.353-3.157,19c0,32.533,26.468,59,59,59s59-26.467,59-59c0-6.647-1.121-13.033-3.157-19h86.314    c-2.036,5.967-3.157,12.353-3.157,19c0,32.533,26.468,59,59,59c32.532,0,59-26.467,59-59c0-32.533-26.468-59-59-59H137    c-11.028,0-20-8.972-20-20c0-0.169-0.021-0.332-0.025-0.5c0.004-0.168,0.025-0.331,0.025-0.5c0-11.028,8.972-20,20-20h255.331    c35.503,0,68.085-21.966,83.006-55.962c4.439-10.114-0.161-21.913-10.275-26.352c-10.113-4.44-21.913,0.161-26.352,10.275    C430.299,300.125,411.661,313,392.331,313h-240.39L134.09,149h333.309l-9.786,46.917c-2.255,10.813,4.683,21.407,15.495,23.662    c1.378,0.288,2.751,0.425,4.104,0.425c9.271,0,17.59-6.485,19.559-15.92l14.809-71    C512.808,127.19,511.316,121.056,507.519,116.384z M399,434c10.477,0,19,8.523,19,19s-8.523,19-19,19s-19-8.523-19-19    S388.523,434,399,434z M201,434c10.477,0,19,8.524,19,19c0,10.477-8.523,19-19,19s-19-8.523-19-19S190.523,434,201,434z     M250.223,109h-58.435l60.716-60.716l29.217,29.218L250.223,109z M306.792,109l60.716-60.716L428.223,109H306.792z"
                          data-original="#000000"
                          className="active-path"
                          data-old_color="#000000"
                          fill="#FFFFFF"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                {t("ventes.title")} <i className="fa fa-angle-down horizontal-icon" />
              </a>
              <ul className="sub-menu">
                <li aria-haspopup="true">
                  <a href="/Aujourdhui_ventes" className="slide-item">
                  {t("ventes.today")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href="/ventes" className="slide-item">
                  {t("suivi.donneventes")}

                  </a>
                </li>
              </ul>
            </li>
            <li aria-haspopup="true">
              <a href="#" className="sub-icon">
              <i className="icon icon-people mr-2" />
                {t("client.title")} 
              </a>
              <ul className="sub-menu">
                <li aria-haspopup="true">
                <a href="/code_client" className="sub-icon">
                {t("client.donneclient")} 
                </a>
                </li>
                <li aria-haspopup="true">
                <a href="/ajouter_client" className="sub-icon">
                {t("client.ajouterClient")} 
                </a>
                </li>
                <li aria-haspopup="true">
                <a href="/liste_client" className="sub-icon">
                {t("client.listeClients")}
                </a>
                </li>
              </ul>
            </li>
          
            <li aria-haspopup="true">
              <a href="/tournee" className="sub-icon">
              <i className="ti-truck mr-1" /> {"  "}
                {t("tournee.title")} 
              </a>
            </li>
            <li aria-haspopup="true">
              <a href="/objectifs" className="sub-icon">
              <i className="fe fe-target" /> {"  "}
                {t("objectifs.title")} 
              </a>
            </li>
            <li aria-haspopup="true">
              <Link to="/top_gammes" /* className="sub-icon  active" */>
                <i className="ti-stats-up fa-1x mr-2"/>
                {t('topgamme.titleGamme')}
              </Link>
            </li>
           
            <li aria-haspopup="true">
              <Link to="#" className="sub-icon">
              <i className="fe fe-clipboard fa-1x mr-2"  />
              {t('todayCommercial.stock')}
               <i className="fa fa-angle-down horizontal-icon" />
              </Link>
              <ul className="sub-menu">
              <li aria-haspopup="true">
                  <a href="/total_stock" className="slide-item">
                  {t('todayCommercial.stockTotal')}
                  </a>
                </li>
              <li aria-haspopup="true">
                  <a href="/liste_inventaire" className="slide-item">
                  {t("todayCommercial.inventaire")}
                  </a>
                </li>
              </ul>
            </li>
            <li aria-haspopup="true">
              <Link to="#" className="sub-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Capa_1"
                  className="icon_img"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <path
                      d="m472 216.061v-100c0-22.056-17.944-40-40-40h-352c-22.056 0-40 17.944-40 40v200c0 22.056 17.944 40 40 40h352c22.056 0 40-17.944 40-40 0-11.046 8.954-20 20-20s20 8.954 20 20c0 44.112-35.888 80-80 80h-156v39.878h70c11.046 0 20 8.954 20 20s-8.954 20-20 20h-180c-11.046 0-20-8.954-20-20s8.954-20 20-20h70v-39.878h-156c-44.112 0-80-35.888-80-80v-200c0-44.112 35.888-80 80-80h352c44.112 0 80 35.888 80 80v100c0 11.046-8.954 20-20 20s-20-8.954-20-20zm-82-71h-119c-11.046 0-20 8.954-20 20s8.954 20 20 20h119c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-163.261-39.419c-8.515-7.035-21.121-5.835-28.157 2.68l-43.205 52.293-17.048-17.507c-7.708-7.915-20.369-8.083-28.282-.376-7.914 7.706-8.083 20.368-.376 28.282l21.454 22.033c.177.182.357.36.541.534 6.483 6.17 15.136 9.604 24.038 9.604.765 0 1.532-.025 2.3-.077 9.644-.643 18.65-5.31 24.737-12.811l46.678-56.497c7.035-8.516 5.835-21.122-2.68-28.158zm163.261 162.419h-119c-11.046 0-20 8.954-20 20s8.954 20 20 20h119c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-163.261-39.543c-8.515-7.035-21.121-5.835-28.157 2.68l-43.216 52.307-17.076-17.438c-7.729-7.893-20.391-8.026-28.283-.296-7.892 7.728-8.024 20.391-.296 28.283l21.454 21.909c.164.167.331.332.5.493 6.483 6.171 15.136 9.604 24.039 9.604.765 0 1.533-.026 2.3-.077 9.644-.643 18.65-5.31 24.737-12.811l46.678-56.497c7.035-8.515 5.835-21.121-2.68-28.157z"
                      data-original="#000000"
                      className="active-path"
                      data-old_color="#000000"
                      fill="#FFFFFF"
                    />
                  </g>
                </svg>
                {t("suivi.Suivi")}
               <i className="fa fa-angle-down horizontal-icon" />
              </Link>
              <ul className="sub-menu">
             {/* <li aria-haspopup="true">
                  <a href="/code_vehicule" className="slide-item">
                  {t('vehicule.entretienvehicule')} 
                  </a>
                </li>*/}
             {/* <li aria-haspopup="true">
                  <a href="/conge" className="slide-item">
                  {t('topgamme.conge')} 
                  </a>
                </li>*/}
                  <li aria-haspopup="true">
                  <a href="/Rapport" className="slide-item">
                  {t("Maps Clients && Ventes")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href="/Calendrier" className="slide-item">
                  {t("suivi.Calendrier")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href="/suivicommercial" className="slide-item">
                  {t("suivi.SuiviCommercial")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href='/reportingCommercial/commercial=-1&datedebut=""&datefin=""' className="slide-item">
                  {t("suivi.ReportingCommercial")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href='/Top10client/commercial=-1&datedebut=""&datefin=""' className="slide-item">
                  {t("listecmdvente.top10clients")}
                  </a>
                </li>
                <li aria-haspopup="true">
                  <a href={'/suiviCommercial/commercial=-1&month='+month} className="slide-item">
                  {t("suivi.suivicommclient")}
                  </a>
                </li>
              </ul>
            </li>
           
            
          </ul>
        </nav>
        {/*Nav end */}
      </div>
    </div>
  );
}

export default TopNavbar;
