
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ErrorAlert from "../../../others/ErrorAlert";
import { getTop10ClientBycommercial } from "../../../services/clients/ClientApi";
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../../components/shared/Loader'
import { getNomCommercials } from '../../../services/Reporting/ReportingApi';
import { Logout } from '../../../services/Login/SignIn';
export default function Top10client() {

  //USE REF
  const history = useHistory();
  const [t, i18n] = useTranslation("global");
  //LIST  CLIENTS
  const [saveList, setSaveList] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false);
  const [liste, setListe] = useState([])
  //------ DATE SEARCH (debut / fin) -----------
  const { commercial, debut, fin } = useParams()
  const [datedebut, setDateDebut] = useState(debut);
  const [dateFin, setDateFin] = useState(fin);
  const [value, setValue] = useState(commercial);//-1


  const getListClient = () => {

    if (datedebut !== "" && dateFin !== "" && value !== -1) {
      if (datedebut <= dateFin) {

        history.push({
          pathname: "/Top10client/commercial=" + value + "&datedebut=" + datedebut + "&datefin=" + dateFin
        })

        getTop10ClientBycommercial(datedebut, dateFin, value, 'bl')
          .then((res) => {
            //setLoading(true)

            if (res?.data) {
              const lists = res?.data.map(item => {
                const copie = { ...item, type: 'tt' }
                return copie
              })
              setSaveList(lists)
              setLoading(false)
            }
          })
          .catch((error) => setError(true))


      } else {
        toast(t('messages.datedebut_fin'), { type: 'error', position: 'bottom-center' });
      }
    } else if (datedebut === "") {
      toast(t('messages.selc_datedebut'), { type: 'warning', position: 'bottom-center' });
    } else if (dateFin === "") {
      toast(t('messages.selc_datefin'), { type: 'warning', position: 'bottom-center' });
    } else if (value === -1) {
      toast(t('messages.selc_commercial'), { type: 'warning', position: 'bottom-center' });
    }
  }

  useEffect(() => {


    getNomCommercials().then((res) => {
      if (res === 401) {
        SeDeconnecter()
      }
      if (res?.data !== null) {
        setListe(res?.data)
      }
    })


    getTop10ClientBycommercial("2024-01-01", "2024-02-01", "00002", 'bl')
      .then((res) => {
        //setLoading(true)

        if (res?.data) {
          setSaveList(res.data)
          setLoading(false)
        }
      })
      .catch((error) => setError(true))

    const SeDeconnecter = () => {
      Logout()
        .then((res) => {
          localStorage.removeItem("authentification_nomadis");
          localStorage.removeItem("url_nomadis");
        });
      history.push({
        pathname: "/code",
      });
    };
  }, []);


  const sendToClientProfile = (code) => {
    if (code !== null) {
      localStorage.setItem("codeclient", code);
      history.push({
        pathname: "/clients",
      });
    }
  }
  const onChangeType = (index, type) => {
    const copielist = [...saveList]
    copielist[index].type = type === 'Qte' ? 'tt' : 'Qte'
    setSaveList(copielist)

  }


  return (

    <div>
      {loading ? <Loader /> : (
        <>
          <div className="section-title">
            <button className="btn bg-primary " onClick={() => history.goBack()}>
              <i className="fe fe-arrow-left" />
              {t("stock.retour")}
            </button>
          </div>
          <div className="panel-group" id="accordion1" role="tablist" aria-multiselectable="true" style={{ marginBottom: '10px' }}>
            <div className="panel panel-default active">
              <div className="panel-heading " role="tab" id="headingOne">
                <h4 className="panel-title">
                  <a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <i className="icon icon-grid text-primary mr-2" /> {t("listecmdvente.top10clients")}
                    <span className="float-right"> <i className="fe fe-search  text-primary" />  </span>
                  </a>
                </h4>
              </div>
              <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div className="panel-body">
                  <div className="form row no-gutters">
                    <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                      <input
                        className="form-control fc-datepicker"
                        type="date"
                        value={datedebut}
                        onChange={(e) => setDateDebut(e.target.value)}
                      />
                    </div>
                    <div className="form-group  col-xl-4 col-lg-4 col-md-4 col-6 mb-0">
                      <input
                        className="form-control fc-datepicker"
                        type="date"
                        value={dateFin}
                        onChange={(e) => setDateFin(e.target.value)}
                      />
                    </div>
                    <div className="form-group  col-xl-3 col-lg-3 col-md-4 mb-0">
                      <div className="d-flex" >
                        <select className="form-control"
                          value={value}
                          onChange={(e) => {
                            setValue(e.target.value)
                          }}>
                          <option value={"-1"}> {"Commercial  --- "}</option>
                          {liste?.map((items, keys) => (
                            <option value={items.code} key={keys}> {items.user} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-12 col-12 mb-0">
                      <button
                        className="br-tl-md-0 br-bl-md-0 btn btn-block btn-success fs-16" onClick={() => getListClient()}>
                        <i className="fe fe-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container" dir={i18n.dir('')}>
            {/** Table Relevee  */}
            <div className="row" style={{ marginTop: '10px' }}>
              {saveList?.map((items, keys) => (
                <div className="col-sm-6 col-xl-4 col-md-6 col-12" key={keys}>
                  <div className="pricing-table active">
                    <div className="price-header bg-primary">


                      <h6 className="title text-white" style={{ fontSize: "14px" }}> <b>{t("client.codeclient") + " : "}</b>

                        {<u style={{ cursor: "pointer" }} onClick={() => sendToClientProfile(items.client.code)} className="text-white">{items.client.code}</u>}
                      </h6>
                      <span className="permonth text-primary" style={{ fontSize: "10px" }}><b>{items.client.nom} </b></span>
                    </div>
                    <div className="price-body">
                      <ul>
                        <li><b>{t("client.TotalVente") + " : "}</b>{items.client.CA}</li>
                        <li><b> {t("client.nbBl") + " : "}</b>
                          <b dir={i18n.dir('fr')}>{
                            items.client.nbr_document
                          }</b> - <b> {t("NB/Visite") + " : "}</b>
                          <b dir={i18n.dir('fr')}>{
                            items.client.nbr_visites
                          }</b> </li>

                        <li><b> {t("Taux") + " : "}</b>
                          <b dir={i18n.dir('fr')}>{
                            ((items.client.nbr_document / items.client.nbr_visites) * 100).toFixed(2)
                          }%</b> </li>
                        <li>
                          <div className="table-responsive">

                            <table
                              className="table card-table table-striped tutorial-table"
                              cellSpacing={0}
                              width="100%"
                            >
                              <thead>
                                <tr>
                                  <th></th>
                                  <th> <button className="btn bg-primary "
                                    onClick={() => onChangeType(keys, items.type)}
                                  >{items.type !== 'Qte' ? "Quantite" : 'Chiffre'}</button></th>
                                </tr>
                                <tr>
                                  <th className="text-center">{t('libelle')}</th>
                                  <th className="text-center">{t(items.type !== 'Qte' ? 'Chiffre' : 'Quantite ')}</th>

                                </tr>

                              </thead>
                              <tbody>
                                {items.marques?.map(m => (
                                  <tr>
                                    <td className="text-center">{m?.libelle}</td>
                                    <td className="text-center">{t(items.type !== 'Qte' ? m?.total : m?.quantite)}</td>


                                  </tr>
                                ))

                                }
                              </tbody>
                            </table>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="price-footer">

                    </div>
                  </div>
                </div>))}
            </div>
          </div>
        </>
      )}

      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
